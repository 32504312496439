import React from "react";
import { useDispatch } from "react-redux";

import "./CartItem.scss";
import { DeleteOutline as Delete } from "@material-ui/icons";
import CardMedia from "@material-ui/core/CardMedia";
import { Add, Remove } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {ActionTypes} from "./shoppingCartReducer";

const CartItem = ({basketItem} ) => {
  const dispatch = useDispatch();

  const increment = () => {
    dispatch({
      type: ActionTypes.ADD_ITEM,
      payload: basketItem
    });
  }

  const decrement = () => {
    dispatch({
      type: ActionTypes.REMOVE_ITEM,
      payload: basketItem
    });
  }

  const remove = () => {
    dispatch({
      type: ActionTypes.REMOVE_ALL,
      payload: basketItem
    });
  }

  return (
    <div className="cart-item">
      <CardMedia className="item-image" image={basketItem.imageUrl} title={basketItem.title}/>
      <div style={{
        padding: 8,
        borderBottom: "1px solid #ccc",
        flex: "1 1 auto",
        margin: 8
      }}>
        <div className="item-detail">
          <h4>{basketItem.title}</h4>
          <span className="subtotal">${basketItem.subtotal}</span>
        </div>
        <div className="item-quantity">
          <span>
            <IconButton size="small" onClick={decrement}>
              <Remove/>
            </IconButton>
            <span>{basketItem.quantity}</span>
            <IconButton size="small" onClick={increment}>
              <Add/>
            </IconButton>
          </span>
          <IconButton size="small" onClick={remove}>
            <Delete/>
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default CartItem;
