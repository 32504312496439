import React from "react";
import LineItem from "./LineItem";

const Invoice = ({basket, total}) => {
  return (
    <ul>
      { basket.map((basketItem, index) => <LineItem key={index} basketItem={basketItem}/>)}
      <li key="total" className="line-item total">
        <span className="total-label">Total</span>
        <span className="total-value">${total}</span>
      </li>
    </ul>
  )
}

export default Invoice;
