import React from "react";
import Button from "@material-ui/core/Button";
import { ShoppingCartOutlined as ShoppingCartIcon } from "@material-ui/icons";
import Badge from "@material-ui/core/Badge";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

function Header(props) {
  const history = useHistory();
  const { toggleNavMenu, hideCart, ...rest} = props;
  const itemCount = useSelector(store => store.cart.itemCount);

  return (
    <header>
      <div className="header-content">
        <div className="header-section">
          <a className="logo" href="#" onClick={() => {
            history.push("/");
            return false;
          }}>
            <span className="narrow">
              Awsome
            </span>
            <span className="bold">
              Pets
            </span>
          </a>
        </div>
        <div className="header-section">
          {!hideCart ? (
            <Button onClick={toggleNavMenu}>
              <ShoppingCartIcon style={{ fontSize: 30 }}/>
              <Badge
                style={{marginTop: 16}}
                badgeContent={itemCount}
                color="secondary" vertical="bottom"/>
            </Button>
          ) : null}
        </div>
      </div>
    </header>
  );
}

export default Header;
