const defaultOptions = {
  headers: {
    "Content-Type": "application/json"
  }
}

const apiRoot = document.querySelector("link[rel='api-root']");
let baseUrl = undefined;
if(apiRoot) {
  baseUrl = apiRoot.getAttribute("href");
  if(baseUrl && baseUrl.charAt(baseUrl.length - 1) === "/") {
    baseUrl = baseUrl.slice(0, baseUrl.length - 1);
  }
}

function _callEndpoint(endpoint, options=defaultOptions, method="GET") {
  const mergedOptions = {...defaultOptions, ...options};
  mergedOptions.method = method;

  // TODO: work out better serialization
  if(typeof mergedOptions.body === "object" && mergedOptions.headers) {
    const contentType = mergedOptions.headers["Content-Type"];
    if (contentType === "application/json") {
      mergedOptions.body = JSON.stringify(mergedOptions.body);
    }
  }

  if(mergedOptions.headers) {
    mergedOptions.headers = new Headers(mergedOptions.headers)
  }

  return fetch(endpoint, mergedOptions)
    .then(response => {
      if(!response.ok) {
        return response.json().then(responseBody => {
            throw {
              code: response.status,
              message: responseBody.message
            }
          });
      } else {
        return response;
      }
    })
    .then(response => response.json())
    .catch(error => {
      throw error
    });
}


export function callEndpoint(endpoint, options, method) {
  if(baseUrl && endpoint.charAt(0) === "/") {
    endpoint = baseUrl + endpoint;
  }
  return _callEndpoint(endpoint, options, method);
}
