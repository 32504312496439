import React from "react";
import Button from "@material-ui/core/Button";

import "./ItemCard.scss";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import {useDispatch} from "react-redux";

import { ActionTypes } from "../ShoppingCart/shoppingCartReducer"

const ItemCard = ({item}) => {
  const dispatch = useDispatch();

  const addItem = () => {
    dispatch({
      type: ActionTypes.ADD_ITEM,
      payload: item
    });
  }

  return (
    <Card className="item-card">
      <div>
        <CardMedia className="item-image"
          image={item.imageUrl}
          title={item.title}
        />
        <CardContent className="item-detail">
          <Typography gutterBottom variant="h5" component="h2">
            {item.title}
          </Typography>
          <span>${item.unitPrice}</span>
        </CardContent>
      </div>
      <CardActions>
        <Button size="small" color="primary" onClick={addItem}>
          Add to cart
        </Button>
      </CardActions>
    </Card>
  )
}

export default ItemCard;
