import React from 'react';
import Workspace from './workspace/Workspace';
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import {applyMiddleware, combineReducers, createStore} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { reducer as requestStatusReducer } from "./workspace/requestStatusReducer";
import { reducer as cartReducer} from "./components/ShoppingCart/shoppingCartReducer";
import { reducer as orderReducer} from "./workspace/pages/payment/orderReducer";

import { HashRouter as Router } from "react-router-dom";

export const AppConfig = React.createContext({});

function configureStore() {
  const composeEnhancers = composeWithDevTools({});
  const store = createStore(combineReducers({
    "status" : requestStatusReducer,
    "cart": cartReducer,
    "order": orderReducer
  }), composeEnhancers(applyMiddleware(thunk)));
  return store;
}

function App(props) {
  return (
    <AppConfig.Provider value={props} >
      <Provider store={configureStore()}>
        <Router>
          <Workspace/>
        </Router>
      </Provider>
    </AppConfig.Provider>
  );
}

export default App;
