const initialState = {
  basket: [],
  total: 0,
  itemCount: 0
}

export const ActionTypes = {
  ADD_ITEM: "addItem",
  REMOVE_ITEM: "removeItem",
  REMOVE_ALL: "removeAll",
  CLEAR_BASKET: "clearBasket"
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch(type) {
    case ActionTypes.ADD_ITEM : {
      const newBasket = state.basket.slice();
      const basketItemIndex = newBasket.map(basketItem => basketItem.id).indexOf(payload.id);
      if(basketItemIndex < 0) {
        newBasket.push({
          ...payload,
          quantity: 1,
          subtotal: payload.unitPrice
        });
      } else {
        const basketItem = newBasket[basketItemIndex];
        basketItem.quantity++;
        basketItem.subtotal = basketItem.quantity * basketItem.unitPrice;
      }
      const total = newBasket.map(basketItem => basketItem.subtotal).reduce((result, subtotal) => (result + subtotal), 0);
      const itemCount = newBasket.map(basketItem => basketItem.quantity).reduce((result, count) => (result + count), 0);
      state = { basket: newBasket, total, itemCount };
      break;
    }
    case ActionTypes.REMOVE_ITEM : {
      const newBasket = state.basket.slice();
      const basketItemIndex = newBasket.map(basketItem => basketItem.id).indexOf(payload.id);
      if(basketItemIndex > -1) {
        const basketItem = newBasket[basketItemIndex];
        if(basketItem.quantity > 1) {
          basketItem.quantity--;
          basketItem.subtotal = basketItem.quantity * basketItem.unitPrice;
        } else {
          newBasket.splice(basketItemIndex, 1);
        }
        const total = newBasket.map(basketItem => basketItem.subtotal).reduce((result, subtotal) => (result + subtotal), 0);
        const itemCount = newBasket.map(basketItem => basketItem.quantity).reduce((result, count) => (result + count), 0);
        state = { basket: newBasket, total, itemCount}
      }
      break;
    }
    case ActionTypes.REMOVE_ALL : {
      const newBasket = state.basket.slice();
      const basketItemIndex = newBasket.map(basketItem => basketItem.id).indexOf(payload.id);
      if(basketItemIndex > -1) {
        newBasket.splice(basketItemIndex, 1);
        const total = newBasket.map(basketItem => basketItem.subtotal).reduce((result, subtotal) => (result + subtotal), 0);
        const itemCount = newBasket.map(basketItem => basketItem.quantity).reduce((result, count) => (result + count), 0);
        state = { basket: newBasket, total, itemCount}
      }
      break;
    }
    case ActionTypes.CLEAR_BASKET: {
      state = { basket: [], total: 0, itemCount: 0}
      break;
    }
  }
  return state;
}
