import React, { useEffect } from "react";

import "./ConformationPage.scss";
import {useDispatch, useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {ActionTypes} from "../../../components/ShoppingCart/shoppingCartReducer";

const ConfirmationPage = () => {
  const history = useHistory();
  const orderId = useSelector(store => store.order.orderId);
  const amountPaid = useSelector(store => store.order.amountPaid);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: ActionTypes.CLEAR_BASKET
    });
  }, []);

  return (
    <div className="confirmation">
      <p>
        Thank you for shopping at Octank Awsome Pets. You have paid an amount of ${amountPaid}
      </p>
      <p>
        Your order reference is <b>{orderId}</b>.
      </p>

      <div className="buttons">
        <Button color="primary" size="medium" variant="contained"
                onClick={() => { window.location.href = "/"}}>
          Back to shopping
        </Button>
      </div>
    </div>);
}

export default ConfirmationPage;
