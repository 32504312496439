import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Drawer } from "@material-ui/core";

import {pageRoutes, defaultRoute} from "./pageRoutes";
import Header from "./Header.js";
import Footer from "./Footer.js";

import './workspace.scss';
import ShoppingCart from "../components/ShoppingCart/ShoppingCart";

function Workspace() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleNavMenu = (open) => {
    if(open === undefined) {
      open = !drawerOpen;
    }
    setDrawerOpen(open);
  };

  return (
    <div>
      <Switch>
        {
          pageRoutes.map((route, index) =>
            <Route key={index}
                   path={route.path}
                   exact={route.exact !== undefined ? route.exact : (!(route.props && route.props.routes))}
                   render={() =>
                     React.createElement(WorkspaceBody, {...route.props, content: route.pageContent, toggleNavMenu: toggleNavMenu})
                   }
            />)
        }
        <Route exact path="/">
          <Redirect to={defaultRoute} />
        </Route>
      </Switch>
      <Footer/>
      <Drawer anchor="right" open={drawerOpen} onClose={() => {toggleNavMenu(false)} }>
        <ShoppingCart close={() => { toggleNavMenu(false)}}/>
      </Drawer>

    </div>
  )
}

function WorkspaceBody(props) {
  const { content, hideCart, navGroup, toggleNavMenu, ...contentProps} = props;
  return (
    <>
      <Header routes={pageRoutes} selected={navGroup} toggleNavMenu={toggleNavMenu} hideCart={hideCart}/>
      <div className="workspace-content">
        { React.createElement(content, contentProps) }
      </div>
    </>
  )
}

export default Workspace;
