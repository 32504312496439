const initialState = {
  status: "new",
  orderId: "",
  message: "",
  clientCallbackUrl: ""
}

export const ActionTypes = {
  PLACE_ORDER_REQUEST: "placeOrderRequest",
  PLACE_ORDER_SUCCESS: "placeOrderSuccess",
  PLACE_ORDER_FAIL: "placeOrderFailure",

  PROCESS_ORDER_REQUEST: "processOrderRequest",
  PROCESS_ORDER_SUCCESS: "processOrderSuccess",
  PROCESS_ORDER_FAILURE: "processOrderFailure"
}

export const reducer = (state = initialState, action) => {
  const { type, payload} = action;
  switch(type) {
    case ActionTypes.PLACE_ORDER_SUCCESS: {
      state = {
        ...state,
        status: payload.status ? payload.status : "placed",
        clientCallbackUrl: payload.clientCallbackUrl,
        orderId: payload.orderId
      }
      break;
    }
    case ActionTypes.PLACE_ORDER_FAIL: {
      state = {
        ...state,
        status: payload.status ? payload.status : "error",
        message: payload.message,
        clientCallbackUrl: undefined
      }
      break;
    }
    case ActionTypes.PROCESS_ORDER_SUCCESS: {
      state = {
        ...state,
        status: payload.status ? payload.status : "success",
        orderId: payload.orderId,
        amountPaid : payload.amountPaid
      }
      break;
    }
    case ActionTypes.PROCESS_ORDER_FAILURE: {
      state = {
        ...state,
        status: payload.status ? payload.status : "failed",
        orderId: payload.orderId,
        message: payload.message,
        clientCallbackUrl: undefined
      }
      break;
    }
  }
  return state;
}
