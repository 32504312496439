import React, { useEffect, useState, useContext } from "react";
import ItemCard from "../../../components/ItemCard/ItemCard";
import "./OfferPage.scss";
import {AppConfig} from "../../../App";
import { callEndpoint} from "../../../APIUtils";

const OfferPage = () => {
  const { endpoints } = useContext(AppConfig);
  const [items, setItems] = useState([]);

  useEffect(() => {
    callEndpoint(endpoints.catalogue)
      .then(items => {
        setItems(items);
      });
  }, []);
  return (
    <div className="page-offer">
      <div className="item-results">
        {
          items.map((item, index) => <ItemCard key={index} item={item}/>)
        }
      </div>
    </div>

  );
}

export default OfferPage;
