import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = document.getElementById('root');
const configNode = root.querySelector('[rel="configuration"]');

const parseConfig = async (configNode) => {
  const configSrc = configNode.getAttribute("src");
  if(configSrc) {
    const response = await fetch(configSrc);
    const config = await response.json();
    return config;
  } else {
    return JSON.parse(configNode.innerHTML)
  }
}

const promise = configNode ? parseConfig(configNode) : Promise.resolve({});
promise.then(config => {
  ReactDOM.render(
    <React.StrictMode>
      <App {...config} />
    </React.StrictMode>, root);
});



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
