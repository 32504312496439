import React, { useEffect } from "react";
import {useSelector} from "react-redux";

import "./ShoppingCart.scss";
import CartItem from "./CartItem";
import {Button} from "@material-ui/core";
import {Clear} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {useHistory} from "react-router-dom";

const ShoppingCart = ({ close }) => {

  const history = useHistory();

  const basketItems = useSelector(store => store.cart.basket);
  const total = useSelector(store => store.cart.total);
  const itemCount = useSelector(store => store.cart.itemCount);

  const checkout = () => {
    close();
    history.push("payment");
  }

  return (
    <div className="shopping-cart">
      <div className="header">
        <IconButton onClick={close}>
          <Clear/>
        </IconButton>
      </div>

      {basketItems.length > 0 ? (
        <div>
          <div className="basket">
            {basketItems.map((basketItem, index) =>
              <CartItem key={index} basketItem={basketItem}/>)}
          </div>
          <div className="total-row">
            <span>Total</span>
            <span className="total">${total}</span>
          </div>
        </div>
      ) : (
        <div className="empty-basket">
          Your shopping cart is empty
        </div>
      )}

      <div className="actions">
        <Button className="checkout-button" disabled={itemCount < 1}
          variant="contained" color="primary" size="medium" onClick={checkout }>
          Checkout
        </Button>
      </div>
    </div>

  );
}

export default ShoppingCart;
