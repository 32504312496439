import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import { TextField, FormControl } from "@material-ui/core";

import "./PaymentPage.scss";
import Button from "@material-ui/core/Button";

import { callEndpoint} from "../../../APIUtils";
import {AppConfig} from "../../../App";
import {ActionTypes} from "./orderReducer";
import Invoice from "./Invoice";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Alert, AlertTitle } from '@material-ui/lab';

const formatCreditCardNumber = cardNumber => {
  cardNumber = cardNumber.replaceAll("-", "");
  const blocks = cardNumber.match(/.{1,4}/g);
  if(blocks) {
    return blocks.join("-");
  } else {
    return "";
  }
}

const validateCreditCardNumberLength = cardNumber => {
  cardNumber = cardNumber.replaceAll("-", "");
  return cardNumber.length < 17;
}

const validateCreditCardInput = cardNumber => {
  cardNumber = cardNumber.replaceAll("-", "");
  return cardNumber.length === 0 || /^\d+$/.test(cardNumber);
}

const formatExpiry = expiry => {
  expiry = expiry.replaceAll("/", "").slice(0, 4);
  if(expiry.length > 2) {
    return [expiry.slice(0, 2), "/", expiry.slice(2)].join('');
  } else {
    return expiry;
  }
}

const validateExpiry = expiry => {
  expiry = expiry.replaceAll("/", "");
  return expiry.length === 0 || /^\d+$/.test(expiry);
}

const validateCCV = ccv => (ccv.length === 0 || /^\d+$/.test(ccv));


const PaymentPage = () => {
  const { endpoints } = useContext(AppConfig);
  const dispatch = useDispatch();
  const history = useHistory();

  const [address, setAddress] = useState("123 Some Street");
  const [city, setCity] = useState("Singapore");
  const [postCode, setPostCode] = useState("2880");

  const [cardNumber, setCardNumber] = useState("");
  const [cardholderName, setCardholderName] = useState("");
  const [expiry, setExpiry] = useState("");
  const [ccv, setCCV] = useState("");

  const basket = useSelector(store => store.cart.basket);
  const total = useSelector(store => store.cart.total);
  const itemCount = useSelector(store => store.cart.itemCount);

  const status = useSelector(store => store.order.status);
  const message = useSelector(store => store.order.message);

  const clientCallbackUrl = useSelector(store => store.order.clientCallbackUrl);
  const orderId = useSelector(store => store.order.orderId);

  const loading = useSelector(store => store.status.loading);
  const submitting = loading.indexOf("placeOrder") > -1 || loading.indexOf("processOrder") > -1;

  const listen = (callbackUrl) => {
    const promise = new Promise((resolve, reject) => {
      const socket = new WebSocket(callbackUrl);
      socket.onopen = () => {
        console.log("Listening to order status update at ", callbackUrl);

        // Check order status if socket still open for 5 second mark
        const pingForOrderStatus = () => {
          if (socket.readyState === WebSocket.OPEN) {
            console.log("Refreshing request for orderId:", orderId);
            socket.send(JSON.stringify({orderId: orderId}));
          }
        }
        setTimeout(pingForOrderStatus, 5000);

        // close websocket if websocket is still open for 1.5 minutes
        const closeSocketDueToTimeout = () => {
          if(socket.readyState === WebSocket.OPEN) {
            socket.close();
            reject(new Error("Websocket timeout"));
          }
        }
        setTimeout(closeSocketDueToTimeout, 90000);


      }
      socket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log("Receive message: ", data);
          if(data.status === "success") {
            socket.close();
            resolve(data);
          } else if(data.status === "failed") {
            socket.close();
            reject(data);
          }
        } catch(exception) {
          socket.close();
          reject(exception);
        }
      }
      socket.onerror = () => {
        reject(new Error("Websocket error"));
      }
    });
    return promise;
  }

  useEffect(() => {
    if(itemCount === 0) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if(status === "success" || status === "processed") {
      history.push("/confirmation");
    }
  }, [status]);

  useEffect(() => {
    if(clientCallbackUrl) {
      dispatch({ type: ActionTypes.PROCESS_ORDER_REQUEST });
      listen(clientCallbackUrl)
        .then(data => {
          dispatch({
            type: ActionTypes.PROCESS_ORDER_SUCCESS,
            payload: data
          });
        })
        .catch(error => {
          dispatch({
            type: ActionTypes.PROCESS_ORDER_FAILURE,
            payload: error
          });
        });
    }
  }, [clientCallbackUrl]);

  const submitPayment = () => {
    const payload = {
      basket: basket.map(basketItem => ({
        id: basketItem.id, title: basketItem.title, quantity: basketItem.quantity
      })),
      shippingAddress: {
        address, city, postCode
      },
      paymentDetail: {
        cardNumber, cardholderName, expiry, ccv
      }
    }
    dispatch({
      type: ActionTypes.PLACE_ORDER_REQUEST
    });

    return dispatch => {
      callEndpoint(endpoints.placeOrder, { body: payload }, "POST")
        .then(data => {
          dispatch({
            type: ActionTypes.PLACE_ORDER_SUCCESS,
            payload: {
              clientCallbackUrl: data.clientCallbackUrl,
              orderId: data.orderId
            }
          });
        })
        .catch(error => {
          dispatch({
            type: ActionTypes.PLACE_ORDER_FAIL,
            payload: { message: error.message }
          });
        });
    }
  }

  const onFormSubmission = () => {
    dispatch(submitPayment());
  }

  const cancel = () => {
    //history.push("/");
    window.location.href = "/";
  }

  return (
    <div className="page-payment">
      <div className="side-bar">
        <Invoice basket={basket} total={total}/>
      </div>

      <div className="main-content">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            onFormSubmission(event);
            return false;
          }}
          onReset={(event) => {
            cancel();
            event.preventDefault();
          }}>
          <h3>Shipping Address</h3>
          <FormControl className="address" fullWidth margin="normal">
            <TextField name="address" label="Address" variant="outlined"
                       value={address} onChange={(event) => { setAddress(event.target.value)}} />
          </FormControl>
          <FormControl className="city" margin="normal">
            <TextField name="city" label="City" variant="outlined"
              value={city} onChange={(event) => { setCity(event.target.value)}}/>
          </FormControl>
          <FormControl className="postCode" margin="normal">
            <TextField name="postCode" label="Post Code" variant="outlined"
              value={postCode} onChange={(event) => { setPostCode(event.target.value)}}/>
          </FormControl>

          <h3>Payment Details</h3>
          <FormControl className="cardNumber" fullWidth margin="normal">
            <TextField name="cardNumber" label="Card Number" variant="outlined"
                       error={!validateCreditCardNumberLength(cardNumber)}
                       helperText={!validateCreditCardNumberLength(cardNumber) ? "Credit card number too long" : null}
              value={cardNumber} onChange={
                (event) => {
                  if(validateCreditCardInput(event.target.value)) {
                    setCardNumber(formatCreditCardNumber(event.target.value));
                  }
                }}/>
          </FormControl>
          <FormControl className="cardholderName" margin="normal">
            <TextField name="cardholderName" label="Cardholder Name" variant="outlined"
              value={cardholderName} onChange={(event) => { setCardholderName(event.target.value)}}/>
          </FormControl>
          <FormControl className="expiry" margin="normal">
            <TextField name="expiry" label="Expiry Date" variant="outlined"
              value={expiry} onChange={(event) => {
                if(validateExpiry(event.target.value)) {
                  setExpiry(formatExpiry(event.target.value))}
                }
              }/>
          </FormControl>
          <FormControl className="ccv" margin="normal">
            <TextField name="ccv" label="CCV" variant="outlined"
              value={ccv} onChange={(event) => {
                if(validateCCV(event.target.value)) {
                  setCCV(event.target.value.slice(0,3))
                }
              }}
            />
          </FormControl>

          { status === "error" || status === "failed" ? (
            <Alert severity="error" className="error">
              <AlertTitle>{message}</AlertTitle>
            </Alert>
          ) : null}

          { status === "placed" ? (
              <Alert severity="success">
                <AlertTitle>Your order has been submitted</AlertTitle>
                Awaiting order processing status update for order reference:<br/>
                <b>{orderId}</b>
              </Alert>
          ) : null}

          <div className="buttons">
            <span style={{display: "flex", alignItems:"center"}}>
              <Button type="submit" color="primary" size="large" variant="contained"
                      disabled={submitting}>
                Confirm Payment
              </Button>
              <CircularProgress size={24} className={`progress ${submitting?"submitting":null}`}/>
            </span>

            <Button type="reset" size="large" variant="contained" disabled={submitting}>
              Cancel
            </Button>
          </div>
        </form>
      </div>

    </div>
  );
}

export default PaymentPage;
