import React from "react";
import "./LineItem.scss";

const LineItem = ({basketItem}) => {
  return (
    <li className="line-item">
      <span className="item-title">{basketItem.title}</span>
      <span className="item-quantity"> x{basketItem.quantity}</span>
      <span className="subtotal">
        ${basketItem.subtotal}
      </span>
    </li>
  )
}
export default LineItem;
