const regexp = new RegExp("(.*)(Request|Success|Failure)");

export const reducer = (state = { loading: [], errors: {}}, action) => {
  const { type, payload } = action;
  const matches = regexp.exec(type);
  if(!matches) {
    return state;
  }

  const [, requestName, status] = matches;
  const loadingIndex = state.loading.indexOf(requestName);
  if(status === "Request") {
    if(loadingIndex === -1) {
      return {
        ...state,
        loading: [...state.loading, requestName]
      }
    } else {
      return state;
    }
  } else {
    if(loadingIndex > -1) {
      state.loading.splice(loadingIndex, 1);
    }
    const errors = {...state.errors};
    if(status === "Success") {
      delete errors[requestName];
    } else if(status === "Failure") {
      errors[requestName] = payload;
    }
    return {
      ...state,
      loading: [...state.loading],
      errors
    };
  }
}

export default reducer;
