import OfferPage from "./pages/offer/OfferPage";
import PaymentPage from "./pages/payment/PaymentPage";
import ConfirmationPage from "./pages/confirmation/ConfirmationPage";

export const pageRoutes = [
  {
    path: "/",
    label: "offer",
    pageContent: OfferPage
  },
  {
    path: "/payment",
    label: "payment",
    pageContent: PaymentPage,
    props: {
      hideCart: true
    }
  },
  {
    path: "/confirmation",
    label: "confirmation",
    pageContent: ConfirmationPage,
    props: {
      hideCart: true
    }
  }
]

export const defaultRoute = "/";
